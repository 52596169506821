.matchBoxCont{
	margin-top: auto;
	margin-bottom: auto;
	width: 100%;
	padding-left: 2%;
	padding-right: 2%;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: relative;
	box-sizing: border-box;



	.playersCont{
		display: flex;
		flex-direction: row;
		width: 95%;
		height: 96%;
		align-items: center;
		position: relative;
		margin: auto;
		justify-content: space-between;
		background-color: rgba(255, 255, 255, 0.303);
		>h3{

			font-size: 25px;
			font-weight: 800;
			margin: 0px;
			color:var(--gameLightColor);
			text-shadow: 3px 3px 3px black;
		}
		

		.player{
			box-sizing: border-box;
			width: 45%;
			

			display: flex!important;
			flex-direction: column!important;

			text-align: center;
			justify-content: center;

			padding: 1%;

			>div{
				justify-content: center;
			}

			&:first-child > div{
				display: flex!important;
				flex-direction: row-reverse!important;
				justify-content: center!important;   
			}



			.stats{
				display: flex;
				width: 100%;
				height: 50px;
				align-items: center;
			}

			.scoreWinner{
				font-size: 30px;
				color: green;
				font-weight: 600;
				text-shadow: 1px 1px 1px black;
			}

			.scoreLoser{
				font-size: 30px;
				color: red;
				font-weight: 600;
				text-shadow: 1px 1px 1px black;
			}

		}

		.player1>div{
			display: flex!important;
			flex-direction: row-reverse!important;
		}
	}

	.winner{
		background-color: rgba(0, 128, 0, 0.303);
	}

	.loser{
		background-color: rgba(255, 0, 0, 0.303);
	}

	a{
		position: absolute;
		bottom: 0%;
		left: 50%;
		transform: translate(-50%, 100%);
		font-size: 14px;
		color: white;
	}
} 