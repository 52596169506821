@import '../../global.scss';

.rangeSlider {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 1.5rem;
	margin: 2rem 0 0 0;
	max-width: 38rem;
}

.sliderContainer {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	user-select: none;
	width: 100%;
}


.slider {
	-webkit-appearance: none;
	appearance: none;
	width: 100%;
	height: 0.4rem;
	border-radius: 4px;
	cursor: pointer;

	background-color: grey;
	box-shadow: 1px 2px 8px black;
	outline: none;
	border: none;
}

.slider::-webkit-slider-thumb {
	-webkit-appearance: none;

	width: 0;
	z-index: 3;
	position: relative;
}

.slider::-moz-range-thumb {
	background-color: transparent;
	border: none;
	width: 0;
	z-index: 3;
}

.progress {
	width: 0;
	height: 0.4rem;
	border-radius: 0.4rem;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
	background: linear-gradient(90deg,
			#55e0ff,
			#3193fc);
	position: absolute;
	pointer-events: none;
	transition-property: box-shadow;
	transition-duration: 0.2s;
}

.timeline {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.sliderThumb {
	position: absolute;
	width: 1.5rem;
	height: 1.5rem;
	border-radius: 50%;
	background-color: white;
	top: 50%;
	left: -0.5rem;
	transform: translateY(-50%);
	z-index: 2;
	pointer-events: none;
}

.sliderValues {
	margin: 0;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;

	small {
		display: flex;
		font-size: 1.5rem;
		width: 3rem;
		border-radius: 5px;
		justify-content: center;
		background-color: #2F4454;
		border: 1px solid grey;
		color: rgb(158, 158, 158);
	}
}

.numberInput {
	background: none;
	color: white;
	width: 3rem;
	border: 1px white solid;
	font-size: 2rem;

	border-radius: 0.4rem;
	outline: none;
	appearance: none;
	text-align: center;
	-moz-appearance: textfield;
}

.numberInput::-webkit-inner-spin-button {
	appearance: none;
}

.rangeSlider:hover .progress {
	box-shadow: 0 0 1rem #55e0ff;
}
