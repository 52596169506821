.imgContainer {
	overflow: hidden;
	display: flex;



	img {
		margin: auto;
		object-fit: cover;
		width: 100%;
		height: 100%;
	}
}
