.searchBar {
	box-sizing: border-box;
	position: relative;
	width: 100%;
	height: 100%;
	max-height: 100%;
	padding-top: 20px;
	position: relative;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	background-color: var(--sociallightcolor);

	.searchInput {
		display: flex;
		width: 75% !important;
		margin-left: auto;
		margin-right: auto;
	}


	input {
		position: relative;
		line-height: 25px;
		margin-right: auto;
		margin-left: auto;
		right: 5px;
	}


	ul {
		overflow: scroll;
		top: 5%;
		height: 100%;
		max-height: 100%;
		min-width: 150px;
		margin: 0px;
		padding: 5%;
		height: 100%;
		list-style: none;

		>li>div {

			aspect-ratio: 10/3 !important;
		}
	}
}
