@import "./../../global.scss";

.popupContainer {
	z-index: 10;
	width: 65%;
	position: fixed;
	bottom: 19px;
	display: flex;
	left: 50%;
	transform: translate(-50%, 0);

	.popupElement {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 5px;
		height: 100%;

		span{
			position: relative;
 			 display: inline-block;
			  line-height: 15px;

		}
	}
}
