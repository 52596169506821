.searchBar {
	box-sizing: border-box;
	position: relative;
	width: 100%;
	height: 100%;
	max-height: 100%;
	padding-top: 20px;
	position: relative;
	display: flex;
	flex-direction: column;
	overflow: hidden;

	background-color: inherit;
	text-align: center;
	font-size: 16px;
	justify-content: center;

	.vs{
		font-weight: 800;
	}

	.searchInput {
		display: flex;
		width: 75% !important;
		margin-left: auto;
		text-align: center;
		margin-right: auto;
		max-width: 300px;

	}


	input {
		position: relative;
		line-height: 25px;
		margin-right: auto;
		margin-left: auto;
		right: 5%;
	}


	>ul {
		overflow: scroll;
		top: 5%;
		height: 100%;
		max-height: 100%;
		min-width: 150px;
		margin: 0px;
		padding: 5%;
		height: 100%;
		list-style: none;

		>li {
			display: flex;
			align-items: center;
			display: flex;

			margin-bottom: 20px;
			flex-direction: column;
			height: 140px;
			background-color: var(--gameLightColor);
			box-shadow: 14px 9px 10px black;
			position: relative;
	
		}
	}
}
