.barContainer {

	display: flex;
	position: relative;
	justify-content: center;

	gap: 10px;
	width: 100%;


	.bar {
		overflow: hidden;
		position: relative;
		border-style: solid;
		border-color: white;
		height: 100%;
		width: 80%;
		justify-content: center;

		.progression {
			
			@keyframes progress {
				0%{
					width: 0%;
				}
				100%{
					width: var(--progression);
				}
			}

			margin-top: auto;
			margin-bottom: auto;
			border-radius: 59px;
			height: 100%;
			background: linear-gradient(to right, #051937, #004b7d, #00849d, #00bb7f, #5eeb12);
			animation: progress 4s forwards;


		}

		
		.placeHolders {
			display: flex;
			flex-direction: column;

			font-weight: 600;
			text-align: center;
			position: absolute;
			top: 50%;
			left: 50%;
			z-index: 4;
			transform: translate(-50%, -50%);
			white-space: nowrap;
			color: black;
		
			.placeHolder{
				font-size: 1em;
				line-height: 1em;
			} 
			.placeHolder2{
				font-size: 0.8em;
				line-height: 0.8em;
			} 
		}




	}


}
