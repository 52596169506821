//BreakPoint
$mobilebreakpoint: 500px;
$mediumbreakpoint: 900px;
$largebreakpoint: 2000px;
$grenatcolor: #2E1E26;
$pinkcolor: #DA7B93;
$greencolor: #04b400;
$greenbarcolor: #478746;
$redcolor: #530808;
$socialcolor: #1C3334;
$sociallightcolor: hsl(183, 39%, 18%);
$lowgreencolor: #376E6F;
$statusgreencolor: #04B400;
$statusgreycolor: #7e7e7e;
$statusyellowcolor: #ffff01;
$statuspinkcolor: #DA7B93;
$mainbgcolor: #2F4454;
$gameLightColor: #4e6374;

:root {
	//MainLayout
	--mainbgcolor: #2F4454;
	--gameLightColor: #52738c;

	//MagicBoxLayout
	--magicboxcolor: #2E1E26;
	--magicboxminwidth: 450px;

	--pinkcolor: #DA7B93;

	--spancolor: rgb(67, 65, 65);

	--profilgreycolor: #D9D9D9;
	--greencolor: #04b400;
	--greenbarcolor: #478746;
	--lowgreencolor: #376E6F;

	--statusgreencolor: #04B400;
	--statusgreycolor: #7e7e7e;
	--statusyellowcolor: #ffff01;
	--statuspinkcolor: #DA7B93;

	--redcolor: #530808;
	--grenatcolor: #2E1E26;
	--socialcolor: #1d3435;
	--sociallightcolor: hsl(183, 39%, 18%);

}

:export {
	mobilebreakpoint: $mobilebreakpoint;
	mediumbreakpoint: $mediumbreakpoint;
	largebreakpoint: $largebreakpoint;
	grenatcolor: $grenatcolor;
	pinkcolor: $pinkcolor;
	greencolor: $greencolor;
	redcolor: $redcolor;
	greenbarcolor: $greenbarcolor;
	socialcolor: $socialcolor;
	sociallightcolor: $sociallightcolor;
	lowgreencolor: $lowgreencolor;
	statusgreencolor: $statusgreencolor;
	statusgreycolor: $statusgreycolor;
	statusyellowcolor: $statusyellowcolor;
	statuspinkcolor: $statuspinkcolor;
	mainbgcolor: $mainbgcolor;
	gameLightColor: $gameLightColor;

}


@font-face {
	font-family: "Text Security";
	src: url("../public/fonts/text-security-disc.ttf") format("opentype");
	font-weight: 400;
}

@font-face {
	font-family: "Sometype Mono";
	src: url("../public/fonts/SometypeMono-Regular.ttf") format("opentype");
	font-weight: 400;
}

@font-face {
	font-family: "Sometype Mono";
	src: url("../public/fonts/SometypeMono-Bold.ttf") format("opentype");
	font-weight: 700;
}


@font-face {
	font-family: "Interstatic";
	src: url("../public/fonts/Interstatic.otf") format("opentype");
	font-weight: 700;
}


