.form {
	
	align-items: center;
	background-color: inherit;


	p {
		color: white;
		text-align: center;
		font-size: 20px; 
	}

	>div{
		margin: auto;
		background-color: inherit;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 10px;
		width: 160px;
		>button{
			width: 80px!important;
		}
	}
}