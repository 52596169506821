@import "../../global";

.profilContainer {
	display: flex;
	position: relative;
	background-color: var(--magicboxcolor);
	width: 100%;
	min-height: 100%;

	@media screen and (max-width:715px) {
		flex-direction: column;
	}

	.profilMenu {
		padding-left: 0.5%;
		position: relative;
		display: flex;
		@media screen and (max-width: 715px) {
				width: 100%;
				height: 190px;
		}


		>div {
			gap: 15px;
			display: flex;
			flex-direction: column;
			width: 20vw;
			height: 14vw;
			max-width: 250px;
			max-height: 150px;
			min-width: 185px;
			min-height: 105px;
			top: 50%;
			transform: translate(0%, -50%);
			position: sticky;
			white-space: nowrap;

			@media screen and (max-width: 715px) {
				
				left: 50%;
	
				position: absolute;
				top : 40px;
				min-width: unset;
				width: 180px;
				transform: rotate(90deg) translate(-0%, 70%);
	
			}

		}

		.profilButton {

			box-shadow: -10px 10px 15px black;
			width: 70%;
			height: 45%;
			display: flex;
			align-items: center;
			text-align: end !important;
			justify-content: right;
			background-color: var(--profilgreycolor);


			div {
				z-index: 10;
				font-size: 18px;
				margin: 0px;
				margin-right: 10%;
			}


		}

		.active {
			width: 95%;
			background-color: var(--profilgreycolor);


			div:first-child {
				opacity: 0;
			}
		}

		@keyframes slide {
			100% {
				width: 95%;
			}
		}


		@keyframes deslide {
			0% {
				width: 95%;
			}

			100% {
				width: 70%;
			}
		}


		.profilButton:hover {
			animation: slide 0.5s forwards;
		}

		.inactive {
			animation: deslide 0.4s forwards;
		}

		@media screen and (min-width:$largebreakpoint) {
			h1 {
				font-size: 45px;
			}
		}

		@media screen and (max-width:$mobilebreakpoint) {
			h1 {
				font-size: 25px;
			}

			button {
				font-size: 17px;
			}
		}
	}

	.profilView {
		position: relative;
		width: calc(100%);
		background-color: var(--grenatcolor);

		.profilSocialOption {
			margin: auto;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.usernameAndPP {
			height: 50px;
			margin: auto;
			margin-top: 40px;
			width: fit-content;

			* {
				font-size: 30px !important;
			}
		}

		@media screen and (max-width: 715px) {
			width: 100%;
			padding-top: 10px;
		}
	}
}
