@import "./../../global.scss";

.pageContainer {
	width: 100%;
	height: 100%;
	position: relative;
	display: flex;
	background-color: var(--mainbgcolor);

	.gameHomeContainer {
		position: relative;
		display: flex;
		flex-direction: column;
		box-sizing: border-box;
		padding: 5%;
		gap: 50px;
		align-items: center;
		margin: auto;
		width: 40%;

	}

	.buttonStyle {
		height: 80px;
		width: min(70vw, 350px);
		box-shadow: -8px 8px 15px black;
	}


	.customGame {
		display: flex;
		margin: auto ;
		box-sizing: border-box;
		align-items: center;
		width: 100%;
		justify-content: center;
		height: 100%;

		position: relative;

		@media screen and (max-width:$mediumbreakpoint) {
			flex-direction: column;
			justify-content: unset;
			padding: 0px;
		}

		.checkBoxContainer {
			width: 55%;
			margin: auto;
			position: relative;
			
			padding: 20px; 	
			
			
			border: 1px solid black;
			border-radius: 10px;

			@media screen and (max-width:$mediumbreakpoint) {
				border: none;
			} 

			>div {
				display: grid;
				gap: 40px;
				grid-template-columns: 1fr 2fr;
				grid-template-rows: auto;
				align-items: flex-end;
				padding: 20px;

				@media screen and (max-width:$mediumbreakpoint) {
					grid-template-rows: 1fr 1fr;
					grid-template-columns: auto;
					padding: 10px;
					gap: 0;
					
				} 	
			}
		

	

			span {
				display: flex;
				font-size: 25px;
				align-items: center;
				justify-content: right;
				text-align: right;
			
				@media screen and (max-width:$mediumbreakpoint) {
					text-align: center;
					justify-content: center!important;
					padding: 0px;
				}
			}
		}

		.buttonCont{
			position: relative;
			
			margin: auto;
			>button{
				width: 250px;
				position: sticky!important;
				bottom: 800px;
				margin-top: 50px;
				margin-bottom: 50px;
				
			}
		}
	}
}




.gameField {
	max-width: 100%;
	max-height: 100%;
	position: relative;
	background-color: var(--gameLightColor);
	opacity: 1;
	aspect-ratio: 3 / 2 !important;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	overflow: hidden;


}

.leaveGameButton{
	position: fixed;
	bottom: 10px;
	left: 50%;
	transform: translate(-50%, 0px);
	width: 120px;
	background-color: var(--gameLightColor)!important;
	
}

.score {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;

	background-color: transparent;
	text-align: center;
	display: flex;
	flex-direction: column;
	gap: 10px;

		&::after {
			content: "";
			margin: auto;
			font-size: min(6vw, 60px);
			animation: countdown 5s forwards;
			font-weight: 800;
			color: white;
			text-shadow: 5px 5px 5px black;
			@keyframes countdown {
				0% {
					content: "3";
				}

				25% {
					content: "3";
				}

				50% {
					content: "2";
				}

				75% {
					content: "1";
				}

				100% {
					content: "GO !";
				}
			}

			
		}



}


	.result {
		text-align: center;
		display: flex;
		justify-content: center;
		flex-direction: column;
		gap: 20px;




		>h2 {
			margin: auto;
			color: var(--gameLightColor);
			text-shadow: 3px 3px 2px black;
			font-size: 30px;
		}

		>span {
			font-size: 28px;

			&:first-letter {
				text-transform: uppercase;
			}

			font-weight: 500;
			text-shadow: 1px 1px 1px black;
			color: seagreen;
		}


	}


