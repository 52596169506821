.avatar {

	text-align: center;
	border-radius: 50%;


	.pp {
		border-radius: 50%;
	}
}
