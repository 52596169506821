@import "./../../global.scss";

.statusIconContainer {
	display: flex;
	gap: 5px;
	width: 100%;
	position: relative;

	.statusIcon {
		aspect-ratio: 1/1 !important;
		border-radius: 50%;
		box-shadow: 1px 1px 2px rgb(44, 43, 43);
	}

	span {
		max-width: 100%;
		text-transform: lowercase;
		margin-top: auto;
		margin-bottom: auto;
		text-shadow: 1px 1px 2px rgb(44, 43, 43);
		display: block;
		align-items: center;
		white-space: nowrap;

		&::first-letter {
			text-transform: capitalize;
		}
	}

	.pending {
		position: relative;
		left: 12px;
		color: coral;
		max-width: calc(100% - 12px);
	}
}
