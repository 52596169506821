@import "./../../global.scss";

.popoverContainer {

	cursor: default;

	position: fixed !important;
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	backdrop-filter: blur(10px);
	z-index: 5;
	
	
	
	>div {
		margin: auto;
		box-shadow: -20px 20px 30px black;
		background-color: var(--sociallightcolor) !important;

		display: flex;
		justify-content: center;
		z-index: 6;
		backdrop-filter: blur(8px);
		position: relative;

		>div {
			position: relative;
			padding-left: 5% !important;
			padding-right: 5% !important;
			height: 100%;
			overflow: scroll;
		}


	}
}
