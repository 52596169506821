.username {
	color: white;
	text-transform: capitalize;
	text-shadow: 3px 3px 2px black;

	max-width: 100%;
	white-space: nowrap;
	text-overflow: ellipsis;

	display: block;
	overflow: hidden;



}
