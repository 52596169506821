.defyPopupCont{
	display: flex;
	flex-direction: column;
	margin: auto;
	gap: 10px;
	color: white;
	font-size: 20px;
	>p::first-letter{
		text-transform: uppercase;
		
	}

}