@import "./../../../global.scss";

.channelManagerContainer {
	width: 100%;

	h1 {
		color: white;
		text-align: center;
	}


	.optionContainer {
		display: flex;
		flex-direction: row;
		width: 100%;
		height: 80%;

		.plusIconContainer {
			position: absolute;
			right: 8%;
			top: 113px;
		}
	}
}
