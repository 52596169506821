	.paddle {
		background-color: white;
		position: absolute;


	}


	.middle {
		position: absolute;
		width: 3px;
		height: 3px;
		background-color: black;
	}


	.ball {
		background-color: white;
		border-radius: 50%;
		position: absolute;
	}

	.fieldBgCont {
		position: relative;
		width: 100%;
		height: 100%;
		color: white;

		.bgScore1 {
			position: absolute;
			top: 10%;
			left: 25%;
			font-size: min(5.2vw, 50px);
			transform: translate(-50%, 0%);
			font-weight: 800;
		}

		.bgScore2 {
			position: absolute;
			font-weight: 800;
			top: 10%;
			left: 75%;
			font-size: min(5.2vw, 50px);
			width: 20px;
			height: 20px;
			transform: translate(-50%, 0%);

		}

		.midLine {
			position: absolute;
			height: 100%;
			width: 0px;
			top: 50%;
			left: 50%;
			transform: translate(calc(-50% + 0.5vw), -50%);
			border-left: 1vw white dashed;
			width: 1vw;
		}
	}
