.crossButton {
	position: absolute;
	width: fit-content;
	display: flex;
	border-radius: 50%;
	top: 3%;
	left: 2%;
	width: 35px;
	height: 35px;
	z-index: 5;
	font-size: 25px;
	text-align: center;
	justify-content: center;
	transform-style: preserve-3d;

	&::after {
		top: 6px;
		left: -5px;
		width: 90%;
		height: 90%;
		background-color: transparent;
		position: absolute;
		z-index: -1;
		border-radius: 50%;
		content: "<";
		border: 3px solid black;
		filter: blur(2px);
		color: #000;

		font-size: 30px;
		transform: translateZ(-1px);
		line-height: 30px;


	}

	&:hover {
		width: 40px;
		height: 40px;

		&::after {
			filter: blur(3px);
			top: 9px;
			left: -8px;
		}
	}

}
